import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import Markers from './markers';

import 'mapbox-gl/dist/mapbox-gl.css';
import { useStyles } from './styles';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAPBOX_TOKEN = `${process.env.GATSBY_MAPBOX_KEY}`;

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const Map = ({location, places}) => {
  const classes = useStyles();
  const mapContainerRef = useRef(null);

  const [map, setMap] = useState(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: `mapbox://styles/${process.env.GATSBY_MAPBOX_STYLES}`,
      zoom: 1,
      continuousWorld: false,
      // renderWorldCopies: false,
      // noWrap: true,
    });

    map.scrollZoom.disable();
    map.doubleClickZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();

    const x = window.matchMedia('(max-width: 600px)');
    if (location.pathname === '/') {
      x.addListener(() => {
        if (x.matches) {
          map.interactive = false;
          map.flyTo({center: [-50, 90]});
        } else {
          map.flyTo({center: map.getCenter()});
        }
      });
    }

    setMap(map);

    return () => map.remove();
  }, [location.pathname]);

  return (
    <div id={'fly'} ref={mapContainerRef} style={mapContainerStyle} className={classes.container}>
      {places && map && <Markers map={map} places={places} />}
    </div>
  );
};

export default Map;
