import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: 0,
    minHeight: '24rem',
    maxHeight: '24rem',
    [theme.breakpoints.only('lg')]: {
      height: '26rem',
      maxHeight: '26rem',
    },
    [theme.breakpoints.only('sm')]: {
      height: 'min-content',
      maxHeight: 'min-content',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'min-content',
      maxHeight: 'min-content',
    },
  },
  container: {
    // padding: '14.75rem 18.5rem 6.5rem',
    // backgroundColor: theme.palette.primary.contrastText,
    clipPath: 'polygon(100% 0, 100% 100%, 0 100%, 0% 5rem)',
    // TODO: remove when customer stories section will be active
    padding: '8.75rem 18.5rem 6.5rem',
    // --- end ---
    [theme.breakpoints.down('md')]: {
      // padding: '10rem 4.5rem 6.5rem',
      padding: '8.75rem 4.5rem 6.5rem', // rm
    },
    [theme.breakpoints.down('sm')]: {
      clipPath: 'polygon(100% 0, 100% 100%, 0 100%, 0% 3rem)',
      // padding: '8rem 2rem 5rem',
      padding: '5rem 2rem 5rem', // rm
    },
    [theme.breakpoints.down('xs')]: {
      clipPath: 'polygon(100% 0, 100% 100%, 0 100%, 0% 3rem)',
      // padding: '8rem 1.5rem 5rem',
      padding: '5rem 1.5rem 5rem', // rm
    },
  },
  description: {
    color: 'black',
    padding: '2rem 14rem',
  },
  titleContainer: {
    paddingBottom: '2rem',
  },
  serviceContainer: {
    padding: '4rem',
  },
  cardContainer: {
    cursor: 'pointer',
    borderRadius: 15,
    padding: '1rem 2rem',
    border: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.cardWhite,
    textAlign: 'center',
    height: '100%',
  },
  textContainer: {
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  imageCard: {
    padding: theme.spacing(1),
    height: '100%',
    maxHeight: 'min-content',
    [theme.breakpoints.down('xs')]: {
      height: 'fit-content',
      marginTop: '1rem',
      padding: 0,
    },
  },
  cardMenuContainer: {
    flexGrow: 1,
    margin: 0,
    [theme.breakpoints.only('sm')]: {
      height: 'min-content'
    },
    [theme.breakpoints.only('xs')]: {
      overflow: 'auto',
      height: 'min-content',
      flexWrap: 'nowrap'
    },
  },
  imageContainer: {
    width: '100% !important',
    height: '100% !important'
  }
}));
