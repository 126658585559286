import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import clsx from 'clsx';

PuzzleCard.defaultProps = {
  setCurrentData: () => {
  },
  setActive: () => {
  },
  active: -1,
  customClasses: null,
};

export default function PuzzleCard({setCurrentData, list, active, setActive, customClasses}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {list.map(industry => <Grid key={`industry-card-${industry.id}`} item container lg={6} md={12} sm={3}>
          <Grid item container justify={'center'} alignItems={'center'}
                className={clsx(
                  classes.cardContainer,
                  industry.id === active && classes.active,
                  industry.title ? customClasses : classes.inactive,
                )}
                onClick={() => {
                  setCurrentData(industry);
                  setActive(industry.id);
                }}
          >
            <div data-sal={'slide-up'} data-sal-duration="1000">
              {industry.title}
            </div>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
