import { makeStyles } from '@material-ui/core';
import { keyframes } from '../../../assets/styles/keyframes';


export const useStyles = makeStyles(theme => ({
  container: {
    padding: '7rem 18.5rem 5rem',
    [theme.breakpoints.down('md')]: {
      padding: '5rem 4.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 1.5rem',
    },
  },
  description: {
    fontFamily: 'Montserrat, sans serif',
    color: 'black',
    padding: '2rem 0',
    // transform: 'translateY(100px)',
    transition: 'all .5s ease',
    transitionProperty: 'top, left, border-radius, background-color',
    transitionDuration: '2s, 1s, 0.5s, 0.5s',
    transform: 'translateY(0)',
    transitionDelay: '0s, 0.5s, 1s, 1.5s',
  },
  titleContainer: {
    paddingTop: '2rem',
  },
  serviceContainer: {
    padding: '3.5rem 3.5rem 1.5rem',
    [theme.breakpoints.down('md')]: {
      padding: '3.5rem 1.5rem 1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0',
    },
  },
  ...keyframes,
}));
