import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Header from '../components/Header';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Layout/Seo';
import Banner from '../components/Banner';
import Services from '../sections/Home/Services';
import Purpose from '../sections/Home/Purpose';
// import Stories from '../sections/Home/Stories';
import Footer from '../components/Footer';
import Location from '../sections/Home/Location';
import Industries from '../sections/Home/Industries';
import { useTranslation } from 'react-i18next';

export default function Home({location}) {
  const {t} =  useTranslation();
  const title = t('homeTitle');
  const subtitle = t('homeSubTitle');
  const { state = {} } = location;
  const {subItemId = ''} = state || {};

  const imageData = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "homeBG.png"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `);

  return (
    <Layout subItemId={subItemId}>
      <Seo title="Home" />
      <Header location={location} />
      <Banner title={title} subtitle={subtitle} img={imageData.file.childImageSharp.fluid} header />
      <Services id={'our-services'} />
      <Purpose id={'why-dekoding'} />
      {/*<Stories id={'customer-stories'} />*/}
      <Industries id={'industries'} />
      <Location id={'our-location'} location={location} />
      <Footer />
    </Layout>
  );
}
