import ConsultancyIcon from '../../../assets/svg/consultancy.svg';
import ExperienceIcon from '../../../assets/svg/experience.svg';
import CloudIcon from '../../../assets/svg/cloud.svg';
import TestingIcon from '../../../assets/svg/testing.svg';
import DevelopmentIcon from '../../../assets/svg/development.svg';


export const servicesList = (t) => [
  {
    id: 1,
    title: t('softwareDevelopment'),
    icon: DevelopmentIcon,
    first: true,
    description: t('softwareDevelopmentDescription'),
  },
  {
    id: 2,
    title:  t('softwareTesting'),
    icon: TestingIcon,
    first: true,
    description: t('softwareTestingDescription'),
  },
  {
    id: 3,
    title:  t('consultancyInnovation'),
    icon: ConsultancyIcon,
    first: true,
    description: t('consultancyInnovationDescription'),
  },
  {
    id: 4,
    title: t('userExperience'),
    icon: ExperienceIcon,
    second: true,
    description: t('userExperienceDescription'),
  },
  {
    id: 5,
    title: t('cloudServices'),
    icon: CloudIcon,
    first: true,
    description: t('cloudServicesDescription'),
  }
];
