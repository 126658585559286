import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { servicesList } from './constants';
import { CustomTitle } from '../../../components/CustomText';
import { useStyles } from './styles';
import ServiceCard from '../../../components/ServiceCard';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function Services({id}) {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Grid id={id} container justify={'center'} className={clsx(classes.container)}
    >
      <CustomTitle title={t('ourServices')} />
      <Grid item lg={6} md={8} sm={9}
            data-sal={'slide-down'}
            data-sal-delay="300"
            data-sal-easing="ease"
      >
        <Typography variant={'body1'} align={'center'} className={classes.description}>
          {t('ourServicesDescription')}
        </Typography>
      </Grid>

      <Grid item container>
        {servicesList(t).map((service, index) => {
          return (
            <Grid item container key={service.id} md={4} sm={6}>
              <ServiceCard service={service} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
