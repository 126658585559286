import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  marketIcon: {
    marginLeft: '-20px',
    marginTop: '-47.5px',
    background: 'transparent',
    border: 'none',
  },
  container: {
    '*:focus': {
      outline: 'none'
    }
  },
}));
