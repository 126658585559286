import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  container: {
    padding: '7rem 18.5rem 5rem',
    [theme.breakpoints.down('md')]: {
      padding: '5rem 4.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 1.5rem',
    },
  },
  description: {
    fontFamily: 'Montserrat, sans serif',
    color: 'black',
    padding: '2rem 0',
  },
  titleContainer: {
    paddingTop: '2rem',
  },
  serviceContainer: {
    padding: '3.5rem 3.5rem 1.5rem',
    [theme.breakpoints.down('md')]: {
      padding: '3.5rem 1.5rem 1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0',
    },
  },
  centerResponsive: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      justifyContent: 'center',
    }
  }
}));
