import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import personIcon from '../../assets/images/GPS.png';

const Marker = ({map, place}) => {
  useEffect(() => {
    const popup = new mapboxgl.Popup({
      offset: 25,
      maxWidth: 800,
    }).setHTML(
      place.text
    );

    const el = document.createElement('img');
    el.id = 'marker';
    el.alt = 'marker';
    el.src = personIcon;
    el.className = 'marker';
    el.style.cursor = 'pointer';

    const marker = new mapboxgl.Marker(el)
      .setLngLat([place.longitude, place.latitude])
      .setPopup(popup) // sets a popup on this marker
      .addTo(map);

    return () => marker.remove();
  });

  return <div />;
};

const Markers = ({map, places}) => {
  return (
    <>
      {places &&
      places.map(place => (
        <Marker key={place.name} map={map} place={place} />
      ))}
    </>
  );
};

export default Markers;
