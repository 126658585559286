import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    // TODO: uncomment when customer stories section will be active
    // backgroundColor: theme.palette.secondary.main,
    // --- end  ---
  },
  bannerContainer: {
    height: '100%',
    width: '100%',
    position: 'relative',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    clipPath: 'polygon(100% 0, 100% 100%, 0 100%, 0% 5rem)',
    minHeight: '70rem',
    [theme.breakpoints.down('sm')]: {
      clipPath: 'polygon(100% 0, 100% 100%, 0 100%, 0% 3rem)',
      minHeight: '65rem',
    },
    [theme.breakpoints.down('xs')]: {
      clipPath: 'polygon(100% 0, 100% 100%, 0 100%, 0% 3rem)',
      minHeight: '50rem',
    },
  },
  locationText: {
    position: 'absolute',
    zIndex: 999,
    top: '23%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    [theme.breakpoints.down('sm')]: {
      top: '23%',
      width: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      top: '23%',
      width: '95%',
    },
  },
  locationTextColor: {
    color: theme.palette.secondary.main,
  },
  locationPosition: {
    position: 'absolute',
    top: '72%',
    left: '35.5%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  locationImg: {
    minHeight: '30rem',
  },
  locationPositionText: {
    backgroundColor: 'white',
    padding: '1rem',
    width: '13rem',
    borderRadius: '15px',
    fontSize: 13,
    position: 'absolute',
    top: '-300%',
    left: '-30.5%',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      top: '-20%',
      left: '160.5%',
    },
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 13,
  },
  phoneNumber: {
    fontSize: 13,
    paddingTop: '1rem'
  },
  locationTextDiv: {
    fontSize: 13,
  },
  mapContainer: {
    bottom: '-55rem',
    width: '71rem',
    height: '100%',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '-52rem',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      bottom: '-25rem',
      width: '100%',
    },
  },
}));
