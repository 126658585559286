import { makeStyles } from '@material-ui/core';
import { keyframes } from '../../../assets/styles/keyframes';


export const useStyles = makeStyles(theme => ({
  container: {
    padding: '14rem 18.5rem 12rem',
    backgroundColor: theme.palette.primary.contrastText,
    clipPath: 'polygon(0 0, 100% 8rem, 100% 100%, 0% 87%)',
    [theme.breakpoints.down('md')]: {
      padding: '14rem 4.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      clipPath: 'polygon(0 0, 100% 3rem, 100% 100%, 0% 95%)',
      padding: '8rem 1.5rem 5rem',
    },
    [theme.breakpoints.down('xs')]: {
      clipPath: 'polygon(0 0, 100% 3rem, 100% 100%, 0% 95%)',
      padding: '8rem 1.5rem 5rem',
    },
  },
  description: {
    color: 'black',
    padding: '2rem 14rem',
  },
  titleContainer: {
    padding: '3rem 0 1rem',
  },
  serviceContainer: {
    padding: '4rem',
  },
  whyContainer: {
    paddingBottom: '3rem',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '1rem',
    },
  },
  ...keyframes,
}));
