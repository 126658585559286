import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { CustomTitle } from '../../../components/CustomText';
import { CustomIndustryCard } from '../../../components/CustomCard';
import { industriesList } from './constants';
import PuzzleCard from '../../../components/PuzzleCard';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export default function Industries({id}) {
  const classes = useStyles();
  const {t} = useTranslation();
  const [active, setActive] = useState(1);
  const {language} = i18n;

  const [currentData, setCurrentData] = useState(industriesList(t)[0]);

  useEffect( () => {
    setCurrentData(industriesList(t)[active - 1]);
    // eslint-disable-next-line
  }, [language]);

  return (
    <Grid id={id} container justify={'center'} className={classes.container}>
      <Grid item xs={12} className={classes.titleContainer}>
        <CustomTitle title={t('industries')} />
      </Grid>
      <Grid item lg={6} md={8} sm={9} className={classes.textContainer}>
        <Typography align={'center'} variant={'body2'} gutterBottom>
          {t('industriesDescription')}
        </Typography>
      </Grid>
      <Grid item xs={12} container className={classes.root}>
        <Grid item xl={6} lg={5} md={3} container spacing={2} className={classes.cardMenuContainer}>
          <PuzzleCard
            list={industriesList(t)}
            setCurrentData={(industry) => setCurrentData(industry)}
            setActive={setActive}
            active={active}
          />
        </Grid>
        <Grid item xl={6} lg={7} md={9} container className={classes.imageCard}>
          <CustomIndustryCard
            title={currentData.title}
            text={currentData.description}
            img={currentData.img}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
