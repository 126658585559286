import React from 'react';
import Map from './map';

const CustomMap = ({location, customClasses, content}) => {
  const places = [
    {
      name: 'Arequipa',
      longitude: -72.535,
      latitude: -7.3739533,
      text: content,
    }
  ];

  return (
    <div id={'map'} className={customClasses}>
      <Map location={location} places={places} />
    </div>
  );
};

export default CustomMap;
