import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import { CustomTitle, } from '../../../components/CustomText';
import { CustomCard } from '../../../components/CustomCard';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export default function Purpose({id}) {
  const classes = useStyles();
  const {t} = useTranslation();

  const imageData = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "whyDekoding.png"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `);

  const data = {
    // title: t('purposeTitle'),
    description: t('purposeDescription'),
    img: imageData.file.childImageSharp.fluid
  };

  return (
    <Grid id={id} container justify={'center'} className={clsx(classes.container, classes.slideDown)}>
      <Grid item xs={12} className={classes.whyContainer}>
        <CustomTitle title={t('whyDekoding')} />
      </Grid>
      <CustomCard img={data.img}>
        {/*<CustomTitleBySection title={data.title} first />*/}
        <Typography
          variant={'body1'}
          align={'right'}
          data-sal={'slide-down'}
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          {data.description}
        </Typography>
      </CustomCard>
    </Grid>
  );
};
