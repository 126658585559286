import financial from '../../../assets/images/financial.jpeg';
import media from '../../../assets/images/media.jpeg';
import health from '../../../assets/images/health.jpeg';
import education from '../../../assets/images/education.jpeg';

export const industriesList = (t) => [
  {
    id: 1,
    title: t('industryExpertiseCard1'),
    description: t('industryExpertiseCard1Description'),
    img: financial,
  },
  {
    id: 2,
    title: t('industryExpertiseCard2'),
    description: t('industryExpertiseCard2Description'),
    img: media,
  },
  {
    id: 3,
    title: t('industryExpertiseCard3'),
    description: t('industryExpertiseCard3Description'),
    img: health,
  },
  {
    id: 4,
    title: t('industryExpertiseCard4'),
    description: t('industryExpertiseCard4Description'),
    img: education,
  }
];
