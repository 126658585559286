import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import CustomMap from '../../../components/CustomMap';
import Typography from '@material-ui/core/Typography';
import { CustomTitle } from '../../../components/CustomText';


export default function Location({id, location}) {
  const classes = useStyles();
  const {t} = useTranslation();

  const popupContentGatsby = `
    <div className={classes.locationPositionText}>
      <strong>
        Arequipa
      </strong>
      <p>
        Augusto Salazar Bondy B16, <br />
        04000 Peru
      </p>
      <p>
         +51 986866268
      </p>
    </div>
  `;

  return (
    <div id={id} className={classes.container}>
      <div className={classes.bannerContainer}>
        <CustomMap location={location} content={popupContentGatsby} customClasses={classes.mapContainer} />
        <div className={classes.locationText}>
          <CustomTitle title={t('ourLocation')} color={'secondary'} />
          <Typography
            color={'secondary'}
            variant={'body1'}
            align={'center'}
            className={classes.description}
            data-sal={'slide-down'}
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            {t('ourLocationDescription')}
          </Typography>
        </div>
      </div>
    </div>
  );
};
