import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { CustomTitleBySection } from '../../components/CustomText';
import { useStyles } from './styles';
import clsx from 'clsx';

ServiceCard.defaultProps = {
  showDescription: true,
  color: 'primary',
  align: 'center',
  textAlign: 'center',
};

export default function ServiceCard({service, showDescription, color, align, textAlign}) {
  const classes = useStyles();
  const Icon = service.icon;

  return (
    <Grid
      item xs={12}
      container
      justify={align}
      className={clsx(classes.serviceContainer, classes.centerResponsive)}
      alignItems={'flex-start'}
    >

      <Grid item>
        <div
          data-sal={service.id % 2 ? 'slide-up' : 'slide-down'}
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          <Icon />
        </div>
      </Grid>
      <Grid item container justify={align} className={clsx(classes.titleContainer, classes.centerResponsive)}>
        <CustomTitleBySection
          title={service.title}
          first={service.first}
          second={service.second} color={color}
          align={align}
        />
      </Grid>
      {showDescription && <Grid item>
        <Typography
          variant={'body2'}
          align={textAlign}
          className={classes.centerResponsive}
          data-sal={service.id % 2 ? 'slide-up' : 'slide-down'}
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          {service.description}
        </Typography>
      </Grid>}
    </Grid>
  );
}
